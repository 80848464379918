import React from "react";
import {
  CbCopy,
  CbVideo,
  CbQuote,
  CbImages,
  CbCta,
  CbButton,
  CbEmbed,
  CbStats,
  CbQuestionAnswers,
} from "@molecules";

const ContentBuilder = ({ blocks }) => {
  if (blocks && blocks.length) {
    const cb = [...blocks];
    return cb?.map((block, i) => {
      const { type, uid } = block || {};
      switch (type) {
        case "lead":
          return <CbCopy {...block} key={uid} lead />;
        case "copy":
          return <CbCopy {...block} key={uid} />;
        case "video":
          return <CbVideo {...block} key={uid} />;
        case "blockQuote":
          return <CbQuote {...block} key={uid} />;
        case "images":
          return <CbImages {...block} key={uid} />;
        case "cta":
          return <CbCta {...block} key={uid} />;
        case "button":
          return <CbButton {...block} key={uid} />;
        case "embedCode":
          return <CbEmbed {...block} key={uid} />;
        case "blockQuoteCustom":
          return <CbQuote {...block} key={uid} />;
        case "stats":
          return <CbStats {...block} key={uid} />;
        case "questionAnswer":
          return <CbQuestionAnswers {...block} key={uid} />;
        default:
          return null;
      }
    });
  }
  return null;
};

ContentBuilder.defaultProps = {};

export default ContentBuilder;
